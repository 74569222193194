import { useState } from 'react'
import { services } from 'utils/constants/services'
import { formattedCost } from 'utils/helper-functions'
import { Facial } from 'utils/types/appTypes'

interface FacialCardProps {
  selectedFacial: string
  facial: Facial
  onSelectFacial: (facial: Facial) => void
}
const FacialCard = ({
  selectedFacial,
  facial,
  onSelectFacial,
}: FacialCardProps) => {
  const [collapse, setCollapse] = useState(false)
  const description = services.find((service) => service.name === facial.name)
  return (
    <div
      className={`facial-card ${
        selectedFacial === facial.name && 'facial-selected'
      }`}
      key={facial.name}
      onClick={() => onSelectFacial(facial)}
    >
      <div className="facial-details">
        <h3 className="facial-name">{facial.name}</h3>
        <span className="facial-price">
          <label>{formattedCost(facial.listPrice, false, 100)}</label>
        </span>
      </div>
      <p className="facial-description">
        {collapse ? description?.long : description?.short}
        {!collapse && (
          <>
            {'.. '}
            <span
              className="option-more-button"
              onClick={() => setCollapse(!collapse)}
            >
              More
            </span>
          </>
        )}
      </p>
    </div>
  )
}

export default FacialCard
