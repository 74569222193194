import React, { FC, useContext } from 'react'
import './commonHeader.scss'
import { ReactComponent as BackIcon } from '../../../assets/icons/leftarrowicon.svg'
import { HeaderTheme } from '../../../utils/types/appTypes'
import { AppContext } from 'providers/context/App'

interface Props {
  backToFunction?: () => void
  theme?: HeaderTheme
}

const CommonHeader: FC<Props> = ({ backToFunction, theme }) => {
  const { appMasterData } = useContext(AppContext)
  return (
    <div
      className={`title-box1 ${
        theme &&
        theme === HeaderTheme.NEUTRAL_TERTIARY &&
        'neutral-tertiary-background'
      }
      ${theme && theme === HeaderTheme.ORANGE && 'light-orange-background'}
      `}
    >
      <div className="upper-main-head1">
        {backToFunction && (
          <div className="back-box">
            <BackIcon className="back-icon" onClick={backToFunction} />
          </div>
        )}
        <span>Book a {appMasterData.isLaserFacial && 'Laser '}Facial</span>
      </div>
    </div>
  )
}

export default React.memo(CommonHeader)
