import { FC, useContext } from 'react'
import { HEALTH_CHECK } from '../../../utils/constants/Enums'
import './health-check.scss'
import { AppContext } from 'providers/context/App'

export const facialCheckInList = [
  'Botox or fillers',
  'Chemical peels',
  'Microneedling',
  'Microblading',
  'Dermaplaning',
  'Microdermabrasion',
  'Laser resurfacing/hair removal (face)',
  'Active/healing cold sore',
]

export const laserCheckInList = [
  'Accutane (within 6 months)',
  'Steroids',
  'Antibiotics',
  'Prescription anticoagulants',
  'Gold therapy (injection + oral)',
  'Impaired immune system',
  'Pregnancy',
  'Lupus',
  'Seizure disorders',
  'Skin cancer',
]

interface Props {
  healthCheck: HEALTH_CHECK
}

const HealthCheckContent: FC<Props> = ({ healthCheck }) => {
  const { appMasterData } = useContext(AppContext)
  const isLaserFacial = appMasterData.isLaserFacial
  const checkInList = isLaserFacial ? laserCheckInList : facialCheckInList
  return (
    <div className="content-wrapper health-check-content">
      <label className="head-label">PRE-FACIAL check-in</label>
      {healthCheck === HEALTH_CHECK.YES ? (
        <>
          <h3>Let’s get some face time at a later date. </h3>
          <p>
            We want to be safe rather than sorry. Please select another
            appointment time that’s 14 days after your most recent cosmetic
            treatment or cold sore.
          </p>
        </>
      ) : (
        <>
          <h3>
            Almost there! ✨ <br />
            {isLaserFacial
              ? 'Have you recently been treated with / for any of the following:'
              : 'Before you book, have you recently had:'}
          </h3>
          <ul className="facialCheckList">
            {checkInList.map((item) => (
              <li key={`checkList-${item}`} data-testid="list-item">
                {item}
              </li>
            ))}
          </ul>
          <p>
            If so, sit tight!{' '}
            <strong>
              {isLaserFacial
                ? 'A laser facial is not recommended at this time, but we are happy to recommend another treatment for you to reach your skin goals.'
                : 'We ask you wait at least 14 days before getting your next facial.'}
            </strong>
          </p>
        </>
      )}
    </div>
  )
}

export default HealthCheckContent
