export const services = [
  {
    name: '50 Minute Facial',
    short: 'Our signature facial is 50 minutes of personalized treatments',
    long: 'Our signature facial includes an in-depth skin analysis, followed by personalized treatments using our exclusive HeydayPro™ products. Your facial will likely include exfoliation, extractions, facial massage, and aromatherapy.',
  },
  {
    name: 'Laser Facial',
    short:
      'We’ll target skin concerns at the source with a dual wavelength laser',
    long: 'Our laser facial targets skin concerns at the source using a dual wavelength laser. Stimulate collagen to plump fine lines, treat pigmentation concerns, and target acne through a laser treatment customized for you.',
  },
]
