import moment from 'moment'
import React, { useContext } from 'react'
import { ReactComponent as MapPinIcon } from '../../../assets/icons/map-pin.svg'
import { AppContext } from '../../../providers/context/App'
import './appointment.scss'
import { getFacialType } from 'utils/helper-functions'

const AppointmentHeader = () => {
  const { appMasterData } = useContext(AppContext)
  return (
    <div className="general-head">
      <h3 className="main-head">
        {getFacialType(appMasterData.isLaserFacial)} Facial{' '}
        {!appMasterData.selectedEsthetician.isAny
          ? `with ${
              appMasterData.selectedEsthetician.staff.firstName
            } ${appMasterData.selectedEsthetician.staff.lastName.charAt(0)}`
          : ''}
      </h3>
      <div className="sub-head">
        {moment(appMasterData.selectedDate).format('dddd, MMM DD')} @{' '}
        {moment
          .tz(appMasterData.selectedTime.startTime, appMasterData.location.tz)
          .format('h:mm A')}
      </div>
      <div className="body-1">
        <MapPinIcon className="mappin-icon" />{' '}
        <span className="location-name">{appMasterData.location.name}</span>
      </div>
    </div>
  )
}

export default React.memo(AppointmentHeader)
