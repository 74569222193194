import React, { useContext, useEffect, useState } from 'react'
import './estheticianTimeslots.scss'
import { Avatar, Button, Chip, Dialog } from '@mui/material'
import { ReactComponent as HIcon } from '../../../assets/icons/H.svg'
import { ReactComponent as Card } from '../../../assets/icons/new-card.svg'
import { ReactComponent as NewIcon } from '../../../assets/icons/new.svg'
import { EstheticianListContext } from '../../../providers/context/EstheticianList'
import {
  Esthetician,
  EstheticianTimeslotsProps,
  TimeSlot,
} from '../../../utils/types/estheticianTypes'
import {
  generateAvatarText,
  getOpeningDate,
} from '../../../utils/helper-functions'
import moment from 'moment-timezone'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../../../providers/context/App'
import {
  GROUP_BOOKING,
  HEALTH_CHECK_ROUTE,
  APPOINTMENT_CONFIRMATION_ROUTE,
  LASER_HEALTH_CHECK_ROUTE,
  LASER_APPOINTMENT_CONFIRMATION_ROUTE,
} from '../../../utils/constants/Helpers'
import {
  addBookableItemToCart,
  getCart,
  reserveTimeslotToCart,
} from '../../../services/Cart'
import {
  FindATimeDateChangeAnalytics,
} from '../../../services/Analytics'
import { ReactComponent as EmptyState } from '../../../assets/images/booking_emptyState_illustration.svg'
import { ReactComponent as TwoMonthAfter } from '../../../assets/images/booking_twoMonth_illustration.svg'
import { GENERAL_ERROR_MESSAGE } from 'utils/constants/Messages'
import { useSnackbar } from 'notistack'
import { useLoading } from 'providers/context/Loading'
import { getMyMembershipVouchers } from 'utils/helper-functions/membershipInfo'



interface LimitEnhancementDialogProps {
  isEnhancementLimitModalOpen: boolean
  setIsEnhancementLimitModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  continueWithoutBoost: (boostName: string) => void
}


export function LimitEnhancementDialog({
  isEnhancementLimitModalOpen,
  setIsEnhancementLimitModalOpen,
  continueWithoutBoost
}: Readonly<LimitEnhancementDialogProps>) {
  return (
    <Dialog className="limit-enhancement-dialog" open={isEnhancementLimitModalOpen} onClose={() => {
      setIsEnhancementLimitModalOpen(false);
    }}>
      <h4>Are you sure?</h4>
      <p>
        The Platinum Party Boost won’t be available on the date selected. If
        you want the Boost, please select a day on or before December 31st.
      </p>
      <Button fullWidth variant="contained" disableElevation className="app-btn change-date" onClick={() => {
        setIsEnhancementLimitModalOpen(false);
      }}>
        Change Date
      </Button>
      <Button fullWidth variant="contained" disableElevation className="app-btn app-btn-outlined" onClick={() => {
        continueWithoutBoost('Platinum Party');
      }}>
        Continue Without Boost
      </Button>
    </Dialog>
  );
}


const EstheticianTimeslots = (props: EstheticianTimeslotsProps) => {
  const { estheticianMasterData } = useContext(EstheticianListContext)
  const { updateData, appMasterData } = useContext(AppContext)
  const [estheticians, setEsthetician] = useState<Esthetician[]>([])
  const [monthValidate, setMonthValidate] = useState(false)
  const [isGhostBooking, setIsGhostBooking] = useState(false)
  const [dateLimit, setDateLimit] = useState(2)
  const [preOpeningDate, setPreOpeningDate] = useState<Date>()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { setLoading } = useLoading()
  const [isEnhancementLimitModalOpen, setIsEnhancementLimitModalOpen] =
    useState(false)
  const [timeEstheticianSelection, setTimeEstheticianSelection] = useState<{
    selectedTime: TimeSlot
    esthetician: Esthetician
  }>()
  const isLaserFacial = appMasterData.isLaserFacial

  const proceedNextScreen = (selectedTime: TimeSlot) => {
    /**
     * - If appointment is within 7 days of booking time
     *   then proceed to Health Check screen
     *   else Appointment detail screen
     *
     */

    const appointmentDate = moment(
      new Date(selectedTime.startTime),
      'DD.MM.YYYY'
    )
    const bookingDate = moment(new Date(), 'DD.MM.YYYY')
    const differenceDays = appointmentDate.diff(bookingDate, 'days')
    if (differenceDays < 6) {
      navigate(isLaserFacial ? LASER_HEALTH_CHECK_ROUTE : HEALTH_CHECK_ROUTE)
    } else {
      navigate(
        isLaserFacial
          ? LASER_APPOINTMENT_CONFIRMATION_ROUTE
          : APPOINTMENT_CONFIRMATION_ROUTE
      )
    }
  }

  const onSelectTime = async (
    selectedTime: TimeSlot,
    esthetician: Esthetician
  ) => {
    // START   -------------------------------------------------------------- Check enhancement time limit with selected date
    setTimeEstheticianSelection({
      selectedTime,
      esthetician,
    })
    const startDate = moment(new Date(selectedTime.startTime), 'DD.MM.YYYY')
    const platinumParty = appMasterData.selectedEnhancements.find(
      (i) => i.name === 'Platinum Party'
    )
    const limitDate = moment(new Date('2023-12-31'), 'DD.MM.YYYY')
    const differenceDays = limitDate.diff(startDate, 'days')
    // Enhancement valid through 31st Dec 2023 only
    if (platinumParty && differenceDays < 0) {
      setIsEnhancementLimitModalOpen(true)
      return
    }
    // END     -------------------------------------------------------------- Check enhancement time limit with selected date
    setLoading(true)
    try {
      const addItemToCart = await addBookableItemToCart(
        appMasterData.cart.cartId,
        appMasterData.category.categoryId,
        esthetician.id !== 'any' ? esthetician.id : ''
      )

      if (
        addItemToCart.addCartSelectedBookableItem?.cart.selectedItems.length &&
        appMasterData.selectedEnhancements.length
      ) {
        await Promise.all(
          appMasterData.selectedEnhancements.map(async (item) => {
            const res = await addBookableItemToCart(
              appMasterData.cart.cartId,
              item.id
            )
            return res
          })
        )
      }

      const getCartValues = await getCart(
        appMasterData.cart.cartId,
        appMasterData.category.categoryId
      )

      const membershipVouchers = (await getMyMembershipVouchers()) ?? []

      appMasterData.selectedEnhancements.forEach((item) => {
        const cartItem = getCartValues.cart.selectedItems.find(
          (obj) => obj.item.id === item.id
        )
        item.cartItemId = cartItem?.id
        return item
      })

      const selectedEnhancements = appMasterData.selectedEnhancements.filter(
        (item) => item.hasOwnProperty('cartItemId')
      )

      updateData({
        ...appMasterData,
        selectedEnhancements,
        membershipVouchers,
        selectedTime: selectedTime,
        selectedEsthetician: esthetician,
        cart: {
          ...appMasterData.cart,
          summary: getCartValues?.cart?.summary ?? appMasterData.cart.summary,
        },
      })
      FindATimeDateChangeAnalytics(selectedTime.startTime)

      await reserveTimeslotToCart(appMasterData.cart.cartId, selectedTime.id)

      proceedNextScreen(selectedTime)
    } catch (error) {
      enqueueSnackbar(GENERAL_ERROR_MESSAGE, {
        variant: 'error',
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    if (estheticianMasterData.filteredEstheticians.length > 0) {
      setEsthetician(estheticianMasterData.filteredEstheticians)
    } else {
      setEsthetician(estheticianMasterData.defaultEsthetician)
    }
  }, [estheticianMasterData])

  useEffect(() => {
    validateMonth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appMasterData.selectedDate])

  useEffect(() => {
    if (appMasterData.location.enableGhostBooking) {
      setIsGhostBooking(true)
    }

    setDateLimit(appMasterData.location.advanceBookingLimit ?? 2)

    const openDate = getOpeningDate(appMasterData.location)
    if (openDate) {
      setPreOpeningDate(openDate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validateMonth = () => {
    const currentData = preOpeningDate ?? new Date()
    const selectedDate = appMasterData.selectedDate
    const difference =
      (selectedDate.getDate() - currentData.getDate()) / 30 +
      selectedDate.getMonth() -
      currentData.getMonth() +
      12 * (selectedDate.getFullYear() - currentData.getFullYear())
    if (difference <= dateLimit) {
      setMonthValidate(false)
    } else {
      setMonthValidate(true)
    }
  }
  let isEmpty =
    Object.values(estheticianMasterData.estheticianTimeslots).flat().length ===
    0 && estheticians.every((a) => !a?.isAny)
  const initialFinished =
    Object.keys(estheticianMasterData.estheticianTimeslots).length > 0
  if (!isEmpty && estheticianMasterData.estheticianTimeslots?.any) {
    isEmpty = estheticianMasterData.estheticianTimeslots.any.length === 0
    if (!isEmpty) {
      props.onFinish()
    }
  }

  const navigateToGroup = () => {
    navigate(GROUP_BOOKING)
  }

  /**
   *
   * @param boostName Enhancement to be remove
   *
   * Remove enhancement from context and process further
   */
  const continueWithoutBoost = (boostName: string) => {
    const indexAt = appMasterData.selectedEnhancements.findIndex(
      (i) => i.name === boostName
    )
    if (indexAt >= 0) {
      const prevSelectedEnhancements = appMasterData.selectedEnhancements
      prevSelectedEnhancements.splice(indexAt, 1)
      updateData({
        ...appMasterData,
        selectedEnhancements: [...prevSelectedEnhancements],
      })
      if (
        timeEstheticianSelection?.selectedTime &&
        timeEstheticianSelection?.esthetician
      ) {
        setLoading(true)
        setIsEnhancementLimitModalOpen(false)
        setTimeout(() => {
          onSelectTime(
            timeEstheticianSelection?.selectedTime,
            timeEstheticianSelection?.esthetician
          )
        }, 2000)
      }
    }
  }

  return (
    <>
      {!isEmpty &&
        !monthValidate &&
        estheticians.map((data, id) => {
          // Hides the last seen and time slots, If Dermaplaning selected.
          if (!props.showFilter && !data.isAny) return null
          return (
            <div key={data.id}>
              <div className="estheticianlist">
                {data.isAny ? (
                  <Chip
                    className="any-chip"
                    avatar={
                      <Avatar>
                        <HIcon />
                      </Avatar>
                    }
                    label="Any Esthetician"
                  />
                ) : (
                  !isGhostBooking && (
                    <div className="estheticianlist-header">
                      <Avatar>
                        {generateAvatarText(
                          data.staff.firstName,
                          data.staff.lastName
                        )}
                      </Avatar>
                      <div className="list-profile">
                        <span className="list-profile-name">
                          {data.staff.firstName} {data.staff.lastName.charAt(0)}
                        </span>
                        {data.lastSeen && (
                          <span className="list-profile-status">
                            Last seen{' '}
                            {moment(data.lastSeen).format('MM/DD/YYYY')}
                          </span>
                        )}
                      </div>
                    </div>
                  )
                )}

                <div className="estheticianlist-timeslots">
                  {!!estheticianMasterData.estheticianTimeslots[data.id] &&
                    estheticianMasterData.estheticianTimeslots[data.id].length >
                    0 ? (
                    estheticianMasterData.estheticianTimeslots[data.id].map(
                      (timeslot) => {
                        return (
                          <div
                            key={timeslot.id}
                            className="timeslots-container"
                            onClick={() => {
                              onSelectTime(timeslot, data).catch((e) =>
                                console.error(e)
                              )
                            }}
                            onKeyDown={() => {
                              onSelectTime(timeslot, data).catch((e) =>
                                console.error(e)
                              )
                            }}
                          >
                            {moment
                              .tz(timeslot.startTime, appMasterData.location.tz)
                              .format('h:mm A')}
                          </div>
                        )
                      }
                    )
                  ) : (
                    <div className="no-slot">
                      Sorry, no available appointments today
                    </div>
                  )}
                </div>
              </div>
              {(estheticians.length === 1 || id === 1) &&
                appMasterData.location.enableGroupBooking ? (
                <div className="new-booking-card-wrapper">
                  <NewIcon className="new-icon" />
                  <div className="txt">
                    <span className="body-1 bold">Spread the glow. </span>
                    <span className="body-1">
                      Book with a friend (or two!) for a group facial.{' '}
                      <span
                        className="link link-txt"
                        onClick={navigateToGroup}
                        onKeyDown={navigateToGroup}
                      >
                        Learn More →
                      </span>
                    </span>
                  </div>
                  <div className="card-image">
                    <Card />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          )
        })}
      {!isEmpty && !monthValidate && props.showFilter && (
        <div className="estheticianlist-footer">
          <p>Looking for someone in particular?</p>
          <span
            className="link"
            onClick={props.openFilter}
            onKeyDown={props.openFilter}
          >
            Filter by Esthetician
          </span>
        </div>
      )}
      {isEmpty && initialFinished && !monthValidate && (
        <div className="no-results">
          <EmptyState />
          <h3>We're all booked up!</h3>
          <p>Try a different day.</p>
        </div>
      )}
      {monthValidate && (
        <div className="no-results-month">
          <TwoMonthAfter />
          <h3>We can’t see that far ahead</h3>
          <div className="parent-wrapper">
            <p>We only allow bookings {dateLimit} months in </p>
            <p>advance. Please try a different date.</p>
          </div>
        </div>
      )}
      <LimitEnhancementDialog
        isEnhancementLimitModalOpen={isEnhancementLimitModalOpen}
        setIsEnhancementLimitModalOpen={setIsEnhancementLimitModalOpen}
        continueWithoutBoost={continueWithoutBoost}
      />
    </>
  )
}

export default React.memo(EstheticianTimeslots)