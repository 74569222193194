import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'
import pkjJson from '../package.json'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: pkjJson.version,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
      networkDetailAllowUrls: [
        'tq4wzdvz.apicdn.sanity.io',
        process.env.REACT_APP_GRAPHQL_API_URL ?? '',
        process.env.REACT_APP_X_SHOPIFY_MIDDLEWARE_API_BASEURL ?? ''
      ],
    }),
  ],
  environment: process.env.REACT_APP_ENV,
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  beforeSend: (sentryEvent) => {
    if (window.location.hostname === 'localhost') {
      console.error({ sentryEvent })
      return null
    }
    return sentryEvent
  },
})

ReactDOM.render(<App />, document.getElementById('root'))

reportWebVitals()
